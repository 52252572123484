<template>
  <div class="product-page">
    <div class="product-banner">
      <img :src="productInfo.productImg || defaultProductImg" />
    </div>
    <div class="details-box">
      <div class="carry-goods">
        <div class="product-name">
          <div class="name-text">
            <span class="name-tag">
              {{ productInfo.deliveryMode == 20 ? "卡密" : "直充" }}
            </span>
            <span> {{ productInfo.productName }}</span>
          </div>
        </div>
        <div class="product-price-box">
          <div class="left-price-box">
            <div class="new-price" v-if="selectAttr.attrId">
              <span class="unit">￥</span>
              <span>{{
                mathManage.formatMoney(selectAttr.product.productSalePrice, 2)
              }}</span>
            </div>
            <div class="new-price" v-else>
              <span class="unit">￥</span>
              <span>{{
                mathManage.formatMoney(productInfo.productMinSalePrice, 2)
              }}</span>
              <span
                v-if="
                  productInfo.productMinSalePrice !=
                  productInfo.productMaxSalePrice
                "
                >~</span
              >
              <span
                v-if="
                  productInfo.productMinSalePrice !=
                  productInfo.productMaxSalePrice
                "
                class="unit"
                >￥</span
              >
              <span
                v-if="
                  productInfo.productMinSalePrice !=
                  productInfo.productMaxSalePrice
                "
                >{{
                  mathManage.formatMoney(productInfo.productMaxSalePrice, 2)
                }}</span
              >
            </div>
            <div class="old-price">
              原价 ￥{{
                mathManage.formatMoney(
                  selectAttr.attrId
                    ? selectAttr.product.productFace
                    : productInfo.productMaxFace,
                  2
                )
              }}
            </div>
          </div>
          <div class="eight-share-box" @click="showClick">
            <img src="../assets/images/share.png" alt="" />
            <span>分享</span>
          </div>
        </div>
        <img
          class="service-img"
          src="../assets/images/service-img.png"
          alt=""
        />
        <div class="coupon-tip-box" v-if="ticketList.length > 0">
          <div class="left-box">
            <img class="icon" src="../assets/images/coupon.png" alt="" />
            <div class="text">
              当前卡券最高可省<span>{{ ticketList[0].amount }}元</span>
            </div>
          </div>
          <div class="right-box" @click="showTicketClick">立即领取</div>
        </div>
      </div>

      <div class="product-property">
        <h3 class="title">商品规格</h3>
        <div class="property-list">
          <div
            v-for="item in propertyList"
            :key="item.specId"
            :class="
              item.isChecked ? 'property-item-box active' : 'property-item-box'
            "
            @click="changeProperty(item)"
          >
            {{ item.specName }}
            <div class="property-item-icon">
              <img src="../assets/images/gou.png" alt="" />
            </div>
          </div>
        </div>
        <h3 class="title facevalue-title">面额</h3>
        <div class="attr-list">
          <div
            :class="item.isChecked ? 'attr-item-box active' : 'attr-item-box'"
            v-for="item in attrList"
            :key="item.attrId"
          >
            <div class="attr-item" @click="changeAttr(item)">
              <div class="attr-item-icon">
                <img src="../assets/images/gou.png" alt="" />
              </div>
              <div v-if="item.attrTag" class="attr-tag one-line-hidden">
                {{ item.attrTag }}
              </div>
              <div class="item attr-name">
                {{ item.attrName }}
              </div>
              <div class="item attr-price">
                <span class="unit">￥</span>
                <span class="price">{{
                  mathManage.formatMoney(item.product.productSalePrice, 2)
                }}</span>
              </div>
              <div class="item attr-facevalue one-line-hidden">
                原价 {{ mathManage.formatMoney(item.product.productFace, 2) }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="purchase-process">
        <div class="purchase-process-top">
          <h4>购买流程</h4>
        </div>
        <ul class="purchase-process-bottom">
          <img
            v-if="productInfo.deliveryMode == 10"
            src="../assets/images/zc.png"
            alt=""
          />
          <img v-else src="../assets/images/km.png" alt="" />
        </ul>
      </div>
    </div>

    <div class="exchange-details">
      <div class="exchange-details-top">
        <h4>温馨提示</h4>
        <!-- <span class="line"></span> -->
      </div>
      <div
        v-if="productInfo.productDetail"
        class="exchange-details-bottom"
        v-html="productInfo.productDetail"
      ></div>
      <div v-else class="exchange-details-bottom">
        <div class="no-data">
          <img :src="require('../assets/images/no-detail.png')" alt="" />
          <span>抱歉，当前商品暂无详情</span>
        </div>
      </div>
    </div>
    <div class="exchange-btn">
      <div v-if="couponObj.couponAfterPrice" class="price-tip-box">
        预计券后购买价格为：￥{{
          mathManage.formatMoney(couponObj.couponAfterPrice, 2)
        }}
      </div>
      <div class="pay-box">
        <div class="service-box">
          <div
            v-for="(item, i) in serviceList"
            :key="i"
            class="service-item service-online"
            @click="serviceAction(item)"
          >
            <a :href="item.href">
              <img class="icon" :src="item.src" alt="" />
              <span class="tip">{{ item.name }}</span>
            </a>
          </div>
        </div>
        <div
          class="pay-btn"
          @click.stop="submitOk"
          :style="{
            background: selectAttr.attrId ? '' : 'rgba(255, 58, 32,0.4)',
          }"
        >
          {{ "立即购买" }}
          <div class="buy-tag" v-if="couponObj.amount">
            立省{{ mathManage.formatMoney(couponObj.amount, 2) }}元
          </div>
        </div>
      </div>
    </div>
    <van-popup
      v-model="showTicket"
      position="bottom"
      :style="{ height: '75%' }"
      overlay-class="ticket-popup"
    >
      <div class="ticket-box">
        <div class="ticket-title">
          <span>卡券领取</span>
          <van-icon @click="showTicket = false" name="clear" />
        </div>
        <div class="ticket-list">
          <h3 class="tic-list-title">可用优惠券</h3>
          <div
            :class="['ticket-item', item.reviceStatus == 0 ? 'disabled' : '']"
            v-for="item in ticketList"
            :key="item.couponId"
          >
            <div class="ticket-price-box">
              <!-- <div class="tag">商品抵扣券</div> -->
              <svg-icon iconClass="vouchercoupon" class="voucher-coupon" />
              <div class="ticket-price one-line-hidden">
                <div class="unit-price">
                  <span class="unit">￥</span>
                  <!-- <span>{{ mathManage.formatMoney(item.amount, 2) }}</span> -->
                  <span>{{ item.amount }}</span>
                </div>
                <div class="title-info">指定商品可用</div>
              </div>
            </div>
            <div class="ticket-info-box">
              <div class="name">{{ item.couponName }}</div>
              <div class="time">
                <span>截止至: {{ item.endDate }}</span>
                <span
                  class="use-btn get"
                  v-if="item.reviceStatus == 10"
                  @click="receiveCoupon(item)"
                  >立即领取</span
                >
                <span
                  class="use-btn dont-Use"
                  v-if="item.reviceStatus == 0"
                  @click="receiveCoupon(item)"
                  >不可使用</span
                >
                <span
                  class="use-btn isUse to-use"
                  v-if="item.reviceStatus == 20"
                  @click="useCoupon(item)"
                  >去使用</span
                >
              </div>
              <div class="info">
                <svg-icon iconClass="shop_page" class="shop-page" />
                <span class="info-text">
                  {{ item.specName }}；{{ item.attrName }}</span
                >
              </div>
              <span class="circle circle-top"></span
              ><span class="circle circle-bottom"></span>
            </div>
          </div>
        </div>
        <div class="ticket-action">
          <span class="btn" @click="showTicket = false">关闭</span>
        </div>
      </div>
    </van-popup>
    <login-box
      v-model="showLogin"
      @handleCancel="loginCancel"
      @onOk="loginOk"
    />
    <van-popup
      v-model="showShare"
      :closeable="false"
      position="bottom"
      round
      :style="{ height: '3.88rem' }"
      class="overlay-share"
    >
      <div class="line"></div>
      <div class="action-share">
        <div
          class="action-item link"
          @click="copyLink('link')"
          :data-clipboard-text="copyUrl"
        >
          <img src="../assets/images/share1.png" alt="" />
          <span>复制链接</span>
        </div>
        <div class="action-item" @click="share">
          <img src="../assets/images/share2.png" alt="" />
          <span>生成海报</span>
        </div>
      </div>
      <div class="cancel-box">
        <span class="cancel-btn" @click="handleCancel = showShare = false"
          >取消</span
        >
      </div>
    </van-popup>
    <van-popup
      v-model="showPoster"
      :closeable="false"
      position="bottom"
      round
      :style="{ height: '100vh' }"
      class="overlay-poster"
    >
      <div class="poster-img-box">
        <img
          :src="posterUrl"
          :style="{ height: isSafari ? '55vh' : '' }"
          alt=""
        />
        <div class="cancel-btn" @click="showPoster = false">
          <van-icon name="close" class="close" />
        </div>
      </div>
      <div class="tip-box">
        <img src="../assets/images/click-btn.png" alt="" /> 长按图片保存至手机
      </div>
    </van-popup>
  </div>
</template>

<script>
import {
  Button,
  Icon,
  Image as VanImage,
  Grid,
  GridItem,
  Cell,
  CellGroup,
  Stepper,
  Field,
  Popup,
} from "vant";
import GameTemplate from "../components/GameTemplate.vue";
import Clipboard from "clipboard";
import {
  getDigitaldetail,
  GetAvailableReceiveCouponsByGroupId,
  ReceiveCoupon,
  getPosterUrl,
} from "@/api/common";
import { mapActions, mapGetters, mapState } from "vuex";
import LoginBox from "@/components/LoginBox.vue";
import mathManage from "@/utils/mathManage";
import "../assets/images/svg/vouchercoupon.svg";
import "../assets/images/svg/shop_page.svg";
export default {
  name: "ProductDetails",
  data() {
    // 判断是否在Safari浏览器中
    var ua = navigator.userAgent.toLowerCase();
    let isSafari = false;
    if (
      ua.indexOf("applewebkit") > -1 &&
      ua.indexOf("mobile") > -1 &&
      ua.indexOf("safari") > -1 &&
      ua.indexOf("linux") === -1 &&
      ua.indexOf("android") === -1 &&
      ua.indexOf("chrome") === -1 &&
      ua.indexOf("ios") === -1 &&
      ua.indexOf("browser") === -1
    ) {
      isSafari = true;
    } else {
      isSafari = false;
    }
    return {
      mathManage,
      isSafari,
      productInfo: {},
      showLogin: false,
      showTicket: false,
      ticketList: [],
      propertyList: [],
      attrList: [],
      selectProperty: {},
      selectAttr: {},
      selectCoupon: {}, // 选择的优惠券
      loginAction: null, // 登录触发操作：1、领取卡券；2、立即购买
      showShare: false,
      copyUrl: "", // 复制的链接
      showPoster: false,
      posterUrl: "", // 海报地址
    };
  },
  computed: {
    ...mapState([
      "isLogin",
      "isInWx",
      "defaultProductImg",
      "mallInfo",
      "serviceList",
    ]),
    // 计算预计券和立省后价格
    couponObj: {
      get() {
        const { specId } = this.selectProperty;
        const { attrId } = this.selectAttr;
        if (
          this.isLogin &&
          this.selectCoupon.specId &&
          this.selectCoupon.attrId &&
          specId == this.selectCoupon.specId &&
          attrId == this.selectCoupon.attrId
        ) {
          return this.selectCoupon;
        }
        return {};
      },
      set() {},
    },
  },
  components: {
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Field.name]: Field,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [VanImage.name]: VanImage,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Stepper.name]: Stepper,
    [Popup.name]: Popup,
    GameTemplate,
    LoginBox,
  },

  created() {
    document.title = "商品详情";
    this.groupId = this.$route.query.groupId;
    this.productId = this.$route.query.productId;

    // 复制链接
    const url = location.href;
    this.copyUrl = url;
    // 查询信息
    this.$nextTick(() => {
      this.getProductInfo();
    });
  },
  methods: {
    ...mapActions(["serviceAction"]),
    inputBlur() {
      document.body.scrollIntoView(); // 回顶部
    },
    // 获取商品详情
    async getProductInfo() {
      this.$loading("请稍等...");
      // 通过商品包id查询
      let params = {
        groupId: this.groupId,
      };
      // 通过商品id查询
      if (this.productId) {
        params = {
          productId: this.productId,
        };
      }
      try {
        const res = await getDigitaldetail(params);
        this.$toast.clear();
        if (res && res.code == 0) {
          this.productInfo = res.data || {};
          // 容错 避免数据错误
          const list = (res.data?.specs || []).map((item) => {
            return {
              ...item,
              attrs: (item.attrs || []).map((el) => {
                return {
                  ...el,
                  product: el.product || {},
                };
              }),
            };
          });

          this.propertyList = list;
          const selectProperty = list.find((item) => item.isChecked) || {};
          this.selectProperty = selectProperty;
          const attrList = selectProperty.attrs || [];
          this.attrList = attrList;
          this.selectAttr = attrList.find((item) => item.isChecked) || {};

          // 查询优惠券
          if (res.data.id) {
            this.getCouponList(res.data.id);
          }
        } else {
          this.$toast(res.message);
        }
      } catch (error) {
        this.$toast.clear();
      }
    },

    // 登录成功
    loginOk() {
      this.showLogin = false;
      if (this.loginAction == 1) {
        this.getCouponList(this.id);
        this.showTicket = true;
      }
      if (this.loginAction == 2) {
        this.toConfirm();
      }
    },

    loginCancel() {
      this.loginAction = null;
      this.showLogin = false;
    },

    // 确认
    async submitOk() {
      if (!this.selectAttr.attrId) return;
      if (!this.isLogin) {
        if (this.isInWx) {
          this.$wxLogin();
          return;
        }
        this.loginAction = 2;
        this.showLogin = true;
        return;
      }

      this.$nextTick(() => {
        this.toConfirm();
      });
    },

    toConfirm() {
      if (!this.selectAttr.product?.tenantProductId) {
        return;
      }
      setTimeout(() => {
        const productData = {
          productName: this.productInfo.productName,
          productImg: this.productInfo.productImg,
          specName: this.selectProperty.specName,
          attrName: this.selectAttr.attrName,
          deliveryMode: this.productInfo.deliveryMode,
        };
        sessionStorage.setItem("productData", JSON.stringify(productData));
        const couponId = this.couponObj.couponId || "";
        this.$router.push(
          `/mall/orderConfirm/${this.selectAttr.product?.tenantProductId}?couponId=${couponId}`
        );
      }, 0);
    },

    // 获取券的列表
    async getCouponList(id) {
      const res = await GetAvailableReceiveCouponsByGroupId({ GroupId: id });
      if (res && res.code == 0) {
        const ticketList = res.data?.list || [];
        this.ticketList = ticketList;
        this.selectCoupon =
          ticketList.filter((item) => item.reviceStatus == 20)[0] || {};
      }
    },

    // 立即领取按钮
    showTicketClick() {
      // if (!this.isLogin) {
      //   this.loginAction = 1;
      //   this.showLogin = true;
      //   return;
      // }
      this.showTicket = true;
    },

    // 领取优惠券
    async receiveCoupon(data) {
      const { reviceStatus } = data;
      if (reviceStatus == 0) return;
      if (!this.isLogin) {
        this.loginAction = 1;
        this.showLogin = true;
        return;
      }
      const res = await ReceiveCoupon({
        batchId: data.batchId,
      });
      if (res && res.code == 0) {
        this.$toast("领取成功！");
        this.ticketList = this.ticketList.map((item) => {
          if (item.batchId == data.batchId) {
            return {
              ...item,
              reviceStatus: 20,
            };
          }
          return item;
        });
      } else {
        this.$toast(res.message);
      }
    },

    // 去使用
    useCoupon(data) {
      const { reviceStatus } = data;
      if (reviceStatus == 0) return;
      const propertyList = this.propertyList.map((item) => {
        return {
          ...item,
          isChecked: item.specId == data.specId,
        };
      });
      this.propertyList = propertyList;
      const selectProperty = propertyList.find((item) => item.isChecked) || {};
      this.selectProperty = selectProperty;
      const attrList = (selectProperty.attrs || []).map((item) => {
        return {
          ...item,
          isChecked: item.attrId == data.attrId,
        };
      });
      this.attrList = attrList;
      this.selectAttr = attrList.find((item) => item.isChecked) || {};
      this.selectCoupon = data;
      this.showTicket = false;
      this.$toast("已选择优惠商品");
    },

    // 切换商品规格
    changeProperty(data) {
      const { isChecked, specId } = data;
      if (isChecked) return;
      const propertyList = this.propertyList.map((item) => {
        return {
          ...item,
          isChecked: specId == item.specId,
        };
      });
      this.propertyList = propertyList;
      const selectProperty = propertyList.find((item) => item.isChecked) || {};
      this.selectProperty = selectProperty;
      const attrList = selectProperty.attrs || [];
      this.attrList = attrList;
      this.selectAttr = attrList.find((item) => item.isChecked) || {};
    },

    // 切换面额
    changeAttr(data) {
      const { isChecked, attrId } = data;
      if (isChecked) return;
      const attrList = this.attrList.map((item) => {
        return {
          ...item,
          isChecked: attrId == item.attrId,
        };
      });
      this.attrList = attrList;
      this.selectAttr = attrList.find((item) => item.isChecked) || {};
    },

    // 点击分享按钮
    showClick() {
      this.showShare = true;
    },

    // 复制链接
    copyLink(name) {
      const clipboard = new Clipboard(`.${name}`);
      clipboard.on("success", (e) => {
        this.$toast("复制成功，快去分享吧~");
        this.showShare = false;
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        this.$toast("该浏览器不支持自动复制！");
        // 释放内存
        clipboard.destroy();
      });
    },

    // 分享按钮
    async share() {
      this.$loading("海报生成中...");
      try {
        const res = await getPosterUrl({
          groupId: this.productInfo.id,
        });
        this.$toast.clear();
        if (res && res.code == 0) {
          this.showShare = false;
          this.showPoster = true;
          this.posterUrl = res.data;
        } else {
          this.$toast(res.message);
        }
      } catch (error) {
        this.$toast.clear();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.van-popup {
  border-radius: 0.2rem 0.2rem 0 0;
}
.product-page {
  flex: 1;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;

  /deep/ .van-cell__title {
    color: #000 !important;
  }

  .field-input {
    .van-field--error .van-field__control::placeholder {
      color: #ccc !important;
    }
  }

  .product-banner {
    width: 100%;
    height: 4.48rem;

    > img {
      width: 100%;
      height: 100%;
    }
  }

  .details-box {
    padding: 0 0.16rem;
    box-sizing: border-box;
  }

  .carry-goods {
    background: #ffffff;
    box-shadow: 0rem -0.08rem 0.4rem 0rem rgba(0, 0, 0, 0.06);
    border-radius: 0.16rem;
    transform: translateY(-0.14rem);
    padding: 0.28rem 0.24rem 0.24rem;
    box-sizing: border-box;

    .product-name {
      align-items: center;

      .name-text {
        height: 100%;
        font-size: 0.32rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        vertical-align: middle;
        .name-tag {
          background: #faefed;
          border-radius: 0.04rem;
          font-size: 0.22rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ff0100;
          align-items: center;
          justify-content: center;
          padding: 0 0.12rem;
          display: inline-block;
          text-align: center;
          transform: translateY(-0.04rem);
        }
      }
    }

    .product-price-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // margin-top: 0.24rem;

      .left-price-box {
        display: flex;
        align-items: center;
      }

      .new-price {
        font-size: 0.48rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ff0100;

        .unit {
          font-size: 0.24rem;
        }
      }

      .old-price {
        margin-left: 0.16rem;
        font-size: 0.22rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #b0b1b3;
        text-decoration: line-through;
        padding-top: 0.1rem;
        box-sizing: border-box;
      }

      .eight-share-box {
        font-size: 0.22rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        display: flex;
        align-items: center;

        img {
          width: 0.25rem;
          height: 0.28rem;
          margin-right: 0.14rem;
        }
      }
    }

    .service-img {
      width: 100%;
      height: 0.28rem;
      margin-top: 0.2rem;
      display: block;
    }

    .coupon-tip-box {
      background: #fff7ea;
      border-radius: 0.08rem;
      padding: 0.2rem 0.24rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 0.16rem;

      .left-box {
        display: flex;
        align-items: center;

        .icon {
          width: 0.3rem;
          height: 0.28rem;
        }

        .text {
          font-size: 0.26rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #e2ae72;
          margin-left: 0.2rem;

          span {
            font-weight: 500;
            color: #ff0100;
            margin-left: 0.04rem;
          }
        }
      }

      .right-box {
        width: 1.36rem;
        height: 0.48rem;
        background: linear-gradient(90deg, #e6ba87 0%, #dba163 100%);
        border-radius: 0.24rem;
        font-size: 0.22rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .product-property {
    background: #ffffff;
    border-radius: 0.16rem;
    padding: 0.24rem 0.24rem 0.4rem;
    box-sizing: border-box;

    .title {
      font-size: 0.26rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;

      &.facevalue-title {
        margin-top: 0.2rem;
      }
    }

    .property-list {
      margin-top: 0.14rem;
      display: flex;
      flex-wrap: wrap;

      .property-item-box {
        min-width: 31%;
        // height: 0.68rem;
        overflow: hidden;
        display: flex;
        background: #fff;
        border-radius: 0.08rem;
        border: 0.01rem solid #d8d8d8;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.26rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        box-sizing: border-box;
        padding: 0.1rem 0.2rem;
        margin-bottom: 0.12rem;
        margin-right: 0.12rem;
        position: relative;

        .property-item-icon {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 0.36rem;
          height: 0.36rem;
          display: none;
          align-items: center;
          justify-content: center;

          img {
            width: 100%;
            height: 100%;
          }
        }

        &.active {
          background: #faefed;
          border-color: #ff6d67;
          color: #ff2c27;
          // font-family: PingFangSC-Medium, PingFang SC;
          // font-weight: 500;

          .property-item-icon {
            display: flex;
          }
        }
      }
    }

    .attr-list {
      margin-top: 0.14rem;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      .attr-item-box {
        width: 33.3333%;
        margin-bottom: 0.12rem;
        // overflow: hidden;

        &:nth-child(2n) {
          justify-content: center;
        }

        &:nth-child(3n) {
          justify-content: flex-end;
        }

        .attr-item {
          width: 93%;
          height: 100%;
          background: #f8f8f7;
          border: 0.01rem solid #f8f8f7;
          border-radius: 0.12rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          padding: 0.3rem 0.04rem;
          box-sizing: border-box;
          position: relative;

          .item {
            text-align: center;
          }

          .attr-item-icon {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 0.36rem;
            height: 0.36rem;
            display: none;
            align-items: center;
            justify-content: center;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .attr-tag {
            position: absolute;
            top: 0;
            left: 0;
            height: 0.32rem;
            background: linear-gradient(138deg, #ff9f6c 0%, #ff5e65 100%);
            border-radius: 0.12rem 0rem 0.12rem 0rem;
            font-size: 0.2rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.06rem 0.1rem;
          }

          .attr-name {
            font-size: 0.26rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            margin-top: 0.12rem;
          }

          .attr-price {
            width: 100%;
            font-size: 0.38rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            margin: 0.02rem 0 0.04rem;

            .unit {
              font-size: 0.22rem;
            }
          }

          .attr-facevalue {
            font-size: 0.22rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #b0b1b3;
            text-decoration: line-through;
          }
        }

        &.active {
          .attr-item {
            background: #faefed;
            border: 0.01rem solid #ff6d67;

            .attr-name {
              color: #ff2c27;
              // font-family: PingFangSC-Medium, PingFang SC;
            }

            .attr-price {
              color: #ff2c27;
            }
            .attr-item-icon {
              display: flex;
            }
          }
        }
      }
    }
  }

  .purchase-process {
    height: 2.22rem;
    margin-top: 0.2rem;
    background-color: #fff;
    border-radius: 0.16rem;

    .purchase-process-top {
      padding: 0.2rem 0.3rem;
      > h4 {
        font-size: 0.26rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }
    }

    .purchase-process-bottom {
      padding: 0 0.58rem;
      box-sizing: border-box;
      height: 0.9rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .exchange-details {
    margin-top: 0.2rem;
    background-color: #fff;
    padding: 0 0.3rem;
    margin-bottom: 2rem;

    .exchange-details-top {
      display: flex;
      flex-direction: column;
      align-items: center;

      padding: 0.28rem 0;
      > h4 {
        text-align: center;
        font-size: 0.26rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
      }

      .line {
        width: 0.8rem;
        height: 0.04rem;
        background: #ff7953;
        margin-top: 0.12rem;
      }
    }

    .exchange-details-bottom {
      min-height: 4rem;
      padding-bottom: 1rem;
      font-size: 0.28rem;

      .no-data {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
          width: 2.8rem;
          height: 2.16rem;
          margin-top: 0.8rem;
        }

        span {
          margin-top: 0.4rem;
          font-size: 0.24rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
        }
      }
    }

    .exchange-details-text {
      margin-top: 0.2rem;
      > p {
        font-size: 0.28rem;
        font-weight: 400;
        color: #9b9b9b;
        line-height: 0.5rem;
      }
    }
  }
  .exchange-btn {
    width: 100%;
    position: fixed;
    bottom: 0;
    background-color: #fff;

    .price-tip-box {
      height: 0.56rem;
      background: #010100;
      display: flex;
      align-items: center;
      font-size: 0.26rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffdab0;
      padding-left: 1.6rem;
      box-sizing: border-box;
    }
    .pay-box {
      height: 1rem;
      display: flex;
      align-items: center;
      padding: 0 0.4rem;
      box-sizing: border-box;

      .service-box {
        display: flex;

        .service-item {
          // width: 0.96rem;
          margin-right: 0.29rem;

          a {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
          }

          .icon {
            width: 0.4rem;
            margin-top: 0.02rem;
          }

          .tip {
            font-size: 0.2rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
          }
        }
      }
      .pay-btn {
        flex: 1;
        height: 0.8rem;
        margin: 0 auto;
        background: rgb(255, 58, 32);
        border-radius: 0.4rem;
        font-size: 0.36rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        letter-spacing: 0.01rem;
        position: relative;

        .buy-tag {
          top: -0.4rem;
          right: -0.2rem;
          position: absolute;
          height: 0.52rem;
          padding: 0 0.2rem;
          background: linear-gradient(90deg, #e6ba87 0%, #dba163 100%);
          border-radius: 1.8rem 2rem 2rem 0rem;
          font-size: 0.24rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .ticket-box {
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 0.36rem 0.24rem 0.36rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .ticket-title {
      height: 0.6rem;
      font-size: 0.32rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 700;
      color: #000000;
      text-align: center;
      margin-bottom: 0.34rem;
      line-height: 0.6rem;
      position: relative;
      .van-icon {
        position: absolute;
        line-height: 0.6rem;
        color: #c8c9cc;
        font-size: 0.42rem;
        right: 0.1rem;
      }
    }

    .ticket-list {
      flex: 1;
      overflow-y: auto;
      .tic-list-title {
        font-size: 0.26rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        margin-bottom: 0.24rem;
      }
      .ticket-item {
        border-radius: 0.16rem;
        display: flex;
        margin-bottom: 0.32rem;
        min-height: 2.16rem;

        .ticket-price-box {
          flex: 0 0 2.16rem;
          position: relative;
          background-image: url(../assets/images/coupon-bgc.png);
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          border-radius: 0 0 0 0.2rem;
          .voucher-coupon {
            position: absolute;
            width: 1.2rem;
            height: 0.6rem;

            top: -0.14rem;
            left: -0.11rem;
          }

          .ticket-price {
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;
            font-size: 0.48rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #fdffff;
            padding-top: 0.1rem;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            .unit-price {
              display: flex;
              align-items: center;
              font-size: 0.56rem;
              color: #fdffff;
              .unit {
                font-size: 0.28rem;
                transform: translateY(0.08rem);
              }
            }
            .title-info {
              font-size: 0.2rem;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #fdffff;
              letter-spacing: 0.02rem;
            }
          }
        }

        .ticket-info-box {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 0.2rem 0.16rem 0.12rem;
          box-sizing: border-box;
          // overflow: hidden;
          background-image: url(../assets/images/right-bjt.png);
          background-repeat: no-repeat;
          background-size: 100% 100%;
          position: relative;
          .circle {
            position: absolute;
            left: -0.15rem;
            display: inline-block;
            width: 0.3rem;
            height: 0.3rem;
            background-color: #fff;
            border-radius: 50%;
            &.circle-top {
              top: -0.15rem;
            }
            &.circle-bottom {
              bottom: -0.15rem;
            }
          }
          > div {
            padding: 0 0.12rem;
          }
          .name {
            width: 4.3rem;
            font-size: 0.26rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.06);
            flex: 1;
          }

          .info {
            display: flex;
            font-size: 0.2rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #a1a1a1;
            margin-top: 0.08rem;
            padding-left: 0;
            align-items: center;
            .shop-page {
              width: 0.35rem;
              height: 0.35rem;
              flex: 0 0 0.35rem;
            }
            .info-text {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .time {
            font-size: 0.2rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #a1a1a1;
            margin-top: 0.08rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 0.14rem;
            border-bottom: 0.02rem dashed #ececec;

            .use-btn {
              box-shadow: 0rem 0.02rem 0.06rem 0rem rgba(0, 0, 0, 0.06);
              border-radius: 1rem;
              text-align: center;
              color: #fff;
              display: inline-block;
              padding: 0.07rem 0.26rem;
              font-size: 0.24rem;
            }
            .dont-Use {
              background-color: #d8d8d8;
            }
            .get {
              background: #ff5b42;
              color: #ffffff;
            }
            .to-use {
              background: #fffcfc;
              border: 1px solid #ff5b42;
              color: #ff5b42;
            }
          }
        }

        .ticket-action-box {
          width: 1.76rem;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.28rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #fe0000;
          border-left: 0.02rem dashed rgba(254, 0, 0, 0.5);
          box-sizing: border-box;
          position: relative;
          .to-use {
            width: 1.28rem;
            height: 0.48rem;
            border-radius: 0.24rem;
            border: 0.01rem solid #fe0000;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .has-get {
            position: absolute;
            right: 0;
            top: 0;
            width: 0.64rem;
            height: 0.64rem;
          }
        }

        &.disabled {
          cursor: no-drop !important;

          .tag,
          .ticket-price,
          .name,
          .ticket-action-box {
            color: #666666 !important;
            border-left-color: #666;

            .to-use {
              border-color: #666;
            }
          }

          .tag {
            background: #bbb !important;
          }
        }
      }
    }
    .ticket-action {
      height: 1.26rem;
      background-color: #fff;
      display: flex;
      align-items: flex-end;
      justify-content: center;

      .btn {
        width: 100%;
        height: 0.8rem;
        background: #ff5b42;
        border-radius: 0.4rem;
        font-size: 0.32rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .overlay-share {
    padding: 0.24rem 0;
    box-sizing: border-box;
    background: #f5f5f5;
    .line {
      width: 0.8rem;
      height: 0.12rem;
      background: #c1c1c1;
      border-radius: 0.06rem;
      margin: 0 auto;
    }

    .action-share {
      padding: 0 0.32rem;
      box-sizing: border-box;
      display: flex;
      height: 2rem;
      margin-top: 0.2rem;

      .action-item {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
          width: 0.96rem;
          height: 0.96rem;
          border-radius: 50%;
        }
        span {
          font-size: 0.24rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          margin-top: 0.2rem;
        }
      }
    }

    .cancel-box {
      height: 0.88rem;
      border-radius: 0.44rem;
      display: flex;
      padding: 0 0.32rem;
      box-sizing: border-box;
      margin-top: 0.2rem;

      span {
        flex: 1;
        background: #ffffff;
        border-radius: 0.44rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.32rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
    }
  }

  .overlay-poster {
    background-color: rgba(0, 0, 0, 0.06);
    overflow: hidden;

    .poster-img-box {
      width: 72%;
      position: relative;
      margin: 0 auto;
      margin-top: 1.6rem;

      img {
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 0.24rem;
        image-rendering: -moz-crisp-edges; /* Firefox */
        image-rendering: -o-crisp-edges; /* Opera */
        image-rendering: -webkit-optimize-contrast; /*Webkit (non-standard naming) */
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
      }
    }

    .tip-box {
      width: 4.28rem;
      height: 1rem;
      background: #000000;
      border-radius: 0.16rem;
      opacity: 0.8;
      font-size: 0.28rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      margin-top: 0.64rem;

      img {
        width: 0.46rem;
        height: 0.54rem;
        margin-right: 0.32rem;
      }
    }

    .cancel-btn {
      position: absolute;
      top: -0.8rem;
      right: -0.8rem;
      width: 0.8rem;
      height: 0.8rem;
      display: flex;
      align-items: center;
      justify-content: center;

      .close {
        font-size: 0.6rem;
        color: #ccc;
      }
    }
  }
}
</style>
